
import ExplorerHeader from '@/components/Explorer/ExplorerHeader.vue'
import { CsBreadcrumbs, CsBtn, CsFlex, CsNavigationDrawer } from '@consteel/csuetify'
import Vue from 'vue'

export default Vue.extend({
  name: 'AccountView',
  components: {
    CsFlex,
    ExplorerHeader,
    CsBtn,
    CsNavigationDrawer,
    CsBreadcrumbs,
  },
  computed: {
    currentPage(): string {
      return this.$route.path.replace('/help/', '')
    },
    breadcrumbItems(): { text: string; disabled: boolean }[] {
      return [
        { text: 'Help', disabled: false },
        {
          text: this.currentPage.charAt(0).toUpperCase() + this.currentPage.slice(1),
          disabled: false,
        },
      ]
    },
  },
  methods: {
    navigate(path: string) {
      if (this.currentPage === path) {
        return
      }
      this.$router.push(`/help/${path}`)
    },
  },
})
