
import Vue from 'vue'
import { CsFlex } from '@consteel/csuetify'

export default Vue.extend({
  name: 'HelpCard',
  components: {
    CsFlex,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      open: {
        type: Boolean,
        default: true,
      },
    }
  },
})
