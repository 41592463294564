var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "help-about",
      attrs: {
        "fill-height": "",
        "fill-width": "",
        vertical: "",
        justify: "flex-start",
        gap: "1.5rem",
      },
    },
    [
      _c("help-card", { attrs: { title: _vm.$t("What is Steelspace?") } }, [
        _c("p", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "Steelspace is a continuously evolving cloud-based open platform for structural engineers to store, view, and share structural models. Our aim is also to provide additional services complementing your existing software package functionalities and calculations."
                )
              ) +
              " "
          ),
        ]),
        _c("p", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "The developers behind Steelspace are KÉSZ Holding Inc. and ConSteel Solutions Ltd., recognized companies within the structural engineering industry. If you are interested in collaborating with us or making your model compatible with Steelspace, contact us!"
                )
              ) +
              " "
          ),
        ]),
      ]),
      _c("help-card", { attrs: { title: _vm.$t("Who can use Steelspace?") } }, [
        _c("p", { domProps: { innerHTML: _vm._s(_vm.whoCanUseSteelspace) } }),
      ]),
      _c(
        "help-card",
        {
          attrs: {
            title: _vm.$t("What devices or browsers support Steelspace?"),
          },
        },
        [
          _c("p", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "Currently, Steelspace is optimized for the latest version of Microsoft Edge, Google Chrome, and Mozilla Firefox browsers, so we recommend using one of these browsers to get the best performance of Steelspace. Mobile and tablet devices are not yet supported fully, only desktop computers and laptops with 13 inch screen size or higher."
                  )
                ) +
                " "
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }