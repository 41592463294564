var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "help-how-it-works",
      attrs: {
        "fill-height": "",
        "fill-width": "",
        vertical: "",
        justify: "flex-start",
        gap: "1.5rem",
      },
    },
    [
      _c(
        "help-card",
        { attrs: { title: _vm.$t("How to store a model in Steelspace?") } },
        [
          _c("p", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "Choosing the “Save as” command then the Cloud option in Consteel will save your model to Steelspace."
                  )
                ) +
                " "
            ),
          ]),
          _c("p", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "Under our terms of use, accounts that have not been logged in or used for 12 months are inactive. Unless the account owner or primary contact takes action, your account will be deleted in 7 days. Once the account is deleted, you will not be able to log in and your models will not be available in the future."
                  )
                ) +
                " "
            ),
          ]),
        ]
      ),
      _c("help-card", { attrs: { title: _vm.$t("Model Explorer") } }, [
        _c("p", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "After login stored models are listed and can be accessed in the Model Explorer. Here you can manage and share your models or access models shared with you. Public models are available for view only in Steelspace."
                )
              ) +
              " "
          ),
        ]),
      ]),
      _c("help-card", { attrs: { title: _vm.$t("Model Viewer") } }, [
        _c("p", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "Clicking twice on a model opens the Model Viewer interface where you can inspect the selected model or share it with someone."
                )
              ) +
              " "
          ),
        ]),
        _c("p", [
          _vm._v(" " + _vm._s(_vm.$t("Left side panel options:")) + " "),
        ]),
        _c("p", [
          _c("span", [_vm._v(_vm._s(_vm.$t("Selection tab")))]),
          _c("br"),
          _c("span", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "Members within the model can be selected by materials and section types."
                  )
                ) +
                " "
            ),
          ]),
        ]),
        _c("p", [
          _c("span", [_vm._v(_vm._s(_vm.$t("Loads tab")))]),
          _c("br"),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "The loads in different Load cases can be switched here."
                )
              )
            ),
          ]),
        ]),
        _c("p", [
          _c("span", [_vm._v(_vm._s(_vm.$t("Display tab")))]),
          _c("br"),
          _c("span", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "Display options can be set for loads, supports, and labels."
                  )
                ) +
                " "
            ),
          ]),
        ]),
        _c("p", [
          _vm._v(" " + _vm._s(_vm.$t("Right side panel options:")) + " "),
        ]),
        _c("p", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "Quantity information of the whole model or the selected members can be accessed by clicking on the Info (letter i) icon. Detailed parameters of one selected member, support, or load objects are listed below."
                )
              ) +
              " "
          ),
        ]),
        _c("p", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "Help for navigation and action hotkeys are available by clicking on the Help (question mark) icon."
                )
              ) +
              " "
          ),
        ]),
      ]),
      _c("help-card", { attrs: { title: _vm.$t("Sharing a model") } }, [
        _c("p", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "A selected model can be shared after clicking on the Share icon on the upper right side of the Model Explorer or opening a model in the Model Viewer and clicking on the Share button on the upper right side of the Viewer interface."
                )
              ) +
              " "
          ),
        ]),
        _c("p", [
          _c("span", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "When sharing a model, the email address of the person who you want to share your model with has to be given. If the person does not have a registered account yet, one will be asked to create an account before the model can be viewed."
                  )
                ) +
                " "
            ),
          ]),
          _c("span", [
            _c("b", {
              domProps: {
                innerHTML: _vm._s(_vm.duringThisIntroductoryPeridod),
              },
            }),
          ]),
        ]),
        _c("p", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "During sharing, you can decide whether the person can only view the shared model in Steelspace or open it in Consteel and work with it. You can also decide whether the person can only view the model or the results as well. Currently viewing results in Steelspace is not available."
                )
              ) +
              " "
          ),
        ]),
      ]),
      _c("help-card", { attrs: { title: _vm.$t("Bandwidth") } }, [
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.actionsPerformedWithin) },
        }),
        _c("p", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "Your web browser will save information about models therefore opening the same model multiple times will not consume bandwidth, but only for the first opening. If you exceed your bandwidth limit for the month, you cannot save a new model to Steelspace or you cannot open new or modified models until the end of the month. Be aware that if you use Steelspace in incognito mode, opening models will consume bandwidth every time. Changing browsers will also consume bandwidth, so we recommend sticking to one web browser when you use Steelspace. If you delete temporary cache files from your browser, then opening a model will consume bandwidth again."
                )
              ) +
              " "
          ),
        ]),
        _c("p", [
          _c(
            "b",
            [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("If you would like to request more bandwidth,"))
                ),
              ]),
              _c("span", [_vm._v(_vm._s(" "))]),
              _c("request-more-bandwidth-dialog", {
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        !attrs.disabled
                          ? _c("a", _vm._g(_vm._b({}, "a", attrs, false), on), [
                              _vm._v(" " + _vm._s(_vm.$t("contact us"))),
                            ])
                          : _c("span", [_vm._v(_vm._s(_vm.$t("contact us")))]),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.showRequestMoreModal,
                  callback: function ($$v) {
                    _vm.showRequestMoreModal = $$v
                  },
                  expression: "showRequestMoreModal",
                },
              }),
              _c("span", [_vm._v(_vm._s(" "))]),
              _c("span", [_vm._v(_vm._s(_vm.$t("for special offers!")))]),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }