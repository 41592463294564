var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "help-pricing-and-limitations",
      attrs: {
        "fill-height": "",
        "fill-width": "",
        vertical: "",
        justify: "flex-start",
        gap: "1.5rem",
      },
    },
    [
      _c(
        "help-card",
        { attrs: { title: _vm.$t("Pricing & limitations") } },
        [
          _c("p", { domProps: { innerHTML: _vm._s(_vm.whoCanUseSteelspace) } }),
          _c("p", { domProps: { innerHTML: _vm._s(_vm.additionalServices) } }),
          _c("help-table", { staticClass: "text-12" }, [
            _c("tr", [
              _c("th", { staticClass: "top" }),
              _c("th", { staticClass: "center" }, [
                _vm._v(_vm._s(_vm.$t("Basic"))),
              ]),
              _c("th", { staticClass: "center" }, [
                _vm._v(_vm._s(_vm.$t("Pro"))),
              ]),
              _c("th", { staticClass: "center" }, [
                _vm._v(_vm._s(_vm.$t("Premium"))),
              ]),
            ]),
            _c("tr", [
              _c("td", [_vm._v(_vm._s(_vm.$t("View own model")))]),
              _c("td", { staticClass: "center" }, [
                _c("i", { staticClass: "check" }),
              ]),
              _c("td", { staticClass: "center" }, [
                _c("i", { staticClass: "check" }),
              ]),
              _c("td", { staticClass: "center" }, [
                _c("i", { staticClass: "check" }),
              ]),
            ]),
            _c("tr", [
              _c("td", [_vm._v(_vm._s(_vm.$t("View shared model")))]),
              _c("td", { staticClass: "center" }, [
                _c("i", { staticClass: "check" }),
              ]),
              _c("td", { staticClass: "center" }, [
                _c("i", { staticClass: "check" }),
              ]),
              _c("td", { staticClass: "center" }, [
                _c("i", { staticClass: "check" }),
              ]),
            ]),
            _c("tr", [
              _c("td", [_vm._v(_vm._s(_vm.$t("Moving models/folders")))]),
              _c("td", { staticClass: "center" }, [
                _c("i", { staticClass: "check" }),
              ]),
              _c("td", { staticClass: "center" }, [
                _c("i", { staticClass: "check" }),
              ]),
              _c("td", { staticClass: "center" }, [
                _c("i", { staticClass: "check" }),
              ]),
            ]),
            _c("tr", [
              _c("td", [_vm._v(_vm._s(_vm.$t("Share models")))]),
              _c("td", { staticClass: "center" }, [
                _c("i", { staticClass: "close" }),
              ]),
              _c("td", { staticClass: "center" }, [
                _c("i", { staticClass: "check" }),
              ]),
              _c("td", { staticClass: "center" }, [
                _c("i", { staticClass: "check" }),
              ]),
            ]),
            _c("tr", [
              _c("td", [_vm._v(_vm._s(_vm.$t("Bandwidth")))]),
              _c("td", { staticClass: "center" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("100 MB/month")))]),
              ]),
              _c("td", { staticClass: "center" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("4 GB/month")))]),
              ]),
              _c("td", { staticClass: "center" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("10 GB/month")))]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }