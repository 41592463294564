var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "help-supported-elements",
      attrs: {
        "fill-height": "",
        "fill-width": "",
        vertical: "",
        justify: "flex-start",
        gap: "1.5rem",
      },
    },
    [
      _c("span", { staticClass: "header-text" }, [
        _vm._v(
          _vm._s(
            _vm.$t("The table shows currently supported structural objects.")
          )
        ),
      ]),
      _c("help-table", { staticClass: "text-12" }, [
        _c("tr", [
          _c("th", { staticClass: "top", attrs: { colspan: "2" } }, [
            _vm._v(_vm._s(_vm.$t("Objects"))),
          ]),
          _c("th", { staticClass: "top" }, [_vm._v(_vm._s(_vm.$t("View")))]),
          _c("th", { staticClass: "top" }, [
            _vm._v(_vm._s(_vm.$t("Filtered selection"))),
          ]),
          _c("th", { staticClass: "top" }, [
            _vm._v(_vm._s(_vm.$t("Graphical selection"))),
          ]),
          _c("th", { staticClass: "top" }, [
            _vm._v(_vm._s(_vm.$t("Labeling"))),
          ]),
          _c("th", { staticClass: "top" }, [
            _vm._v(_vm._s(_vm.$t("Related object data"))),
          ]),
        ]),
        _c("tr", [_c("td", { staticClass: "pa-0", attrs: { colspan: "7" } })]),
        _c("tr", [
          _c("td", { staticClass: "top", attrs: { rowspan: "7" } }, [
            _vm._v(" " + _vm._s(_vm.$t("Structurals")) + " "),
          ]),
          _c("td", [_vm._v(_vm._s(_vm.$t("Members")))]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "check" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "check" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "check" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "check" }),
          ]),
          _c("td", { staticClass: "left" }, [
            _vm._v(_vm._s(_vm.$t("Section, material, release"))),
          ]),
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Plates")))]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "check" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "check" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "check" }),
          ]),
          _c("td", { staticClass: "left" }, [
            _vm._v(_vm._s(_vm.$t("Material"))),
          ]),
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Tapered members")))]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "left" }),
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Haunched members")))]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "left" }),
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Link elements")))]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "left" }),
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Diaphragms")))]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "left" }),
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Rigid bodies")))]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "left" }),
        ]),
        _c("tr", [_c("td", { staticClass: "pa-0", attrs: { colspan: "7" } })]),
        _c("tr", [
          _c("td", { staticClass: "top", attrs: { rowspan: "3" } }, [
            _vm._v(" " + _vm._s(_vm.$t("Supports")) + " "),
          ]),
          _c("td", [_vm._v(_vm._s(_vm.$t("Nodal supports")))]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "check" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "check" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "check" }),
          ]),
          _c("td", { staticClass: "left" }),
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Line supports")))]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "left" }),
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Surface supports")))]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "left" }),
        ]),
        _c("tr", [_c("td", { staticClass: "pa-0", attrs: { colspan: "7" } })]),
        _c("tr", [
          _c("td", { staticClass: "top", attrs: { rowspan: "5" } }, [
            _vm._v(" " + _vm._s(_vm.$t("Loads")) + " "),
          ]),
          _c("td", [_vm._v(_vm._s(_vm.$t("Nodal loads")))]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "check" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "check" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "check" }),
          ]),
          _c("td", { staticClass: "left" }, [
            _vm._v(_vm._s(_vm.$t("Load case"))),
          ]),
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Line loads")))]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "check" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "check" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "check" }),
          ]),
          _c("td", { staticClass: "left" }, [
            _vm._v(_vm._s(_vm.$t("Load case"))),
          ]),
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Surface loads")))]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "check" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "check" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "check" }),
          ]),
          _c("td", { staticClass: "left" }, [
            _vm._v(_vm._s(_vm.$t("Load case"))),
          ]),
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Load transfer surfaces")))]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "check" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "check" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "check" }),
          ]),
          _c("td", { staticClass: "left" }),
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Masses")))]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "left" }),
        ]),
        _c("tr", [_c("td", { staticClass: "pa-0", attrs: { colspan: "7" } })]),
        _c("tr", [
          _c("td", { staticClass: "top", attrs: { rowspan: "5" } }, [
            _vm._v(" " + _vm._s(_vm.$t("Special loads")) + " "),
          ]),
          _c("td", [_vm._v(_vm._s(_vm.$t("Prediscribed placements")))]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "left" }),
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Temperature loads")))]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "left" }),
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Predescribed changes in length")))]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "left" }),
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Tension forces")))]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "left" }),
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Meteorological loads")))]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "left" }),
        ]),
        _c("tr", [_c("td", { staticClass: "pa-0", attrs: { colspan: "7" } })]),
        _c("tr", [
          _c("td", { staticClass: "top", attrs: { rowspan: "6" } }, [
            _vm._v(" " + _vm._s(_vm.$t("Special objects")) + " "),
          ]),
          _c("td", [_vm._v(_vm._s(_vm.$t("Frame corners")))]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "left" }),
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Reinforcements")))]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "left" }),
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Dimensions")))]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "left" }),
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Stiffeners")))]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "left" }),
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Joints")))]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "left" }),
        ]),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("Shearfields")))]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "center" }, [
            _c("i", { staticClass: "close" }),
          ]),
          _c("td", { staticClass: "left" }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }