
import Vue, { VueConstructor } from 'vue'
import { IThemeable } from '@consteel/csuetify'
import Themeable from 'vuetify/lib/mixins/themeable'

export default (Vue as VueConstructor<IThemeable>).extend({
  name: 'HelpTable',
  mixins: [Themeable],
  computed: {
    classes() {
      return { 'help-table': true, ...this.themeClasses }
    },
  },
})
