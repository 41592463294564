
import { CsFlex } from '@consteel/csuetify'
import Vue from 'vue'
import HelpCard from './Components/HelpCard.vue'

export default Vue.extend({
  name: 'HelpAbout',
  components: {
    CsFlex,
    HelpCard,
  },
  data() {
    return {
      whoCanUseSteelspace: this.$t(
        'During this introductory period, accessing public models is free for users with a Consteel account registered at <a href="https://consteelsoftware.com" target="_blank">consteelsoftware.com</a>. All additional services of Steelspace are available for users requesting a Consteel Software <a palce="consteelsoftware" href="https://consteelsoftware.com/products/offers-licensing/" target="_blank">subscription plan</a>.'
      ),
    }
  },
})
