var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "help-view",
      attrs: {
        vertical: "",
        "fill-height": "",
        "fill-width": "",
        justify: "flex-start",
      },
    },
    [
      _c("explorer-header"),
      _c("v-divider"),
      _c(
        "cs-flex",
        { attrs: { "fill-width": "", "fill-height": "" } },
        [
          _c(
            "cs-navigation-drawer",
            { staticClass: "py-4 px-6", attrs: { width: 300 } },
            [
              _c(
                "cs-btn",
                {
                  staticClass: "justify-start my-2",
                  attrs: {
                    color: "gray",
                    block: "",
                    large: "",
                    outlined: _vm.currentPage === "about",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.navigate("about")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("About")) + " ")]
              ),
              _c(
                "cs-btn",
                {
                  staticClass: "justify-start my-2",
                  attrs: {
                    color: "gray",
                    block: "",
                    large: "",
                    outlined: _vm.currentPage === "how-it-works",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.navigate("how-it-works")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("How it works")) + " ")]
              ),
              _c(
                "cs-btn",
                {
                  staticClass: "justify-start my-2",
                  attrs: {
                    color: "gray",
                    block: "",
                    large: "",
                    outlined: _vm.currentPage === "supported-elements",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.navigate("supported-elements")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("Supported elements")) + " ")]
              ),
              _c(
                "cs-btn",
                {
                  staticClass: "justify-start my-2",
                  attrs: {
                    color: "gray",
                    block: "",
                    large: "",
                    outlined: _vm.currentPage === "pricing-and-limitations",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.navigate("pricing-and-limitations")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("Pricing & limitations")) + " ")]
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "cs-flex",
            {
              attrs: {
                "fill-width": "",
                "fill-height": "",
                vertical: "",
                align: "flex-start",
              },
            },
            [
              _c(
                "cs-flex",
                { staticClass: "subheader py-3", attrs: { "fill-width": "" } },
                [
                  _c("cs-breadcrumbs", {
                    attrs: { items: _vm.breadcrumbItems },
                  }),
                  _c(
                    "cs-flex",
                    {
                      staticClass: "contact-us-text",
                      attrs: { justify: "space-between" },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "If you have any problems, feel free to contact us!"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c(
                        "cs-btn",
                        {
                          attrs: {
                            small: "",
                            href: "https://consteel.atlassian.net/servicedesk/customer/portal/8",
                            target: "_blank",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Request help")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "cs-flex",
                {
                  staticClass: "router-view py-6",
                  attrs: { "fill-width": "" },
                },
                [_c("router-view")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }