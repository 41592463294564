var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      class: ["help-card", "px-5", "py-3", { open: _vm.open }],
      attrs: { vertical: "" },
    },
    [
      _c(
        "cs-flex",
        {
          staticClass: "help-card-title",
          attrs: { "fill-width": "", justify: "space-between" },
          on: {
            click: function ($event) {
              _vm.open = !_vm.open
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.title))]),
          _c("v-icon", [_vm._v("mdi-chevron-down")]),
        ],
        1
      ),
      _c(
        "cs-flex",
        {
          class: ["help-card-content", { "pt-3": _vm.open }],
          attrs: { "fill-width": "", vertical: "" },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }