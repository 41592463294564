
import { CsFlex } from '@consteel/csuetify'
import Vue from 'vue'
import HelpCard from './Components/HelpCard.vue'
import RequestMoreBandwidthDialog from '../Dialogs/RequestMoreBandwidthDialog.vue'

export default Vue.extend({
  name: 'HelpHowItWorks',
  components: {
    CsFlex,
    HelpCard,
    RequestMoreBandwidthDialog,
  },
  data() {
    return {
      showRequestMoreModal: false,
      duringThisIntroductoryPeridod: this.$t(
        'During this introductory period, only users having a Consteel account registered at <a href="https://consteelsoftware.com" target="_blank">consteelsoftware.com</a> can access Steelspace.'
      ),
      actionsPerformedWithin: this.$t(
        'Actions performed within Steelspace use data bandwidth. The limit of consumable bandwidth is based on subscription levels. Your current subscription is available in your <a href="/account" target="_blank">Account</a>. The amount of used bandwidth will refresh every month based on the date your subscription was created.'
      ),
    }
  },
})
